import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import JotformEmbed from 'react-jotform-embed'
import styled from "styled-components";
import ImageCarousel from "../components/common/ImageCarousel"; // Adjust the import
import { graphql, Link } from "gatsby";


import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const StyledH2 = styled.h2`
  font-weight: 600;
  font-size: 30px;

  @media (max-width: 768px) {
    font-size: 19px;
  }
`

const MesaDrivePage = ({ data }) => {
  const [carouselIsOpen, setCarouselIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const openCarousel = (index) => {
    setCarouselIsOpen(true);
    setSelectedImageIndex(index);
  };

  const closeCarousel = () => {
    setCarouselIsOpen(false);
    setSelectedImageIndex(0);
  };

  const images = data.allFile.nodes.map((node) => node.childImageSharp.gatsbyImageData);

  return (
    <Layout>
      <Seo title="Mesa Drive" />

      <div className="bg-white w-full 2xl:max-w-screen-xl mx-auto">
        <div className="w-full mx-auto pb-5 px-6 xl:px-20">
          <div className="relative">
            <GatsbyImage
              alt="Mesa Drive Hero Background"
              image={getImage(data.heroImage.childImageSharp.gatsbyImageData)}
              className="z-0"
            />
            
            <h1 className="main-title">Mesa Drive</h1>
          </div>
        </div>

        <div className="w-full flex flex-col items-center mx-auto pb-16 px-6 xl:px-20">
          <div className="w-full mx-auto">
            <StyledH2 className="text-site-green mb-3">Wide Selection of Beautiful Plants</StyledH2>

            <p className="font-body text-base italic font-bold mb-3">“Our Most Delicate Specimens”</p>

            <p className="font-body text-base mb-3">Influenced by the cool breeze of the nearby Pacific Ocean, the fresh air of the Palomar Mountains, and the warmth of the valley, our Mesa Drive location in northern San Diego County provides an ideal environment for our most delicate specimens.</p>

            <p className="font-body text-base">With this perfect combination of environmental elements, we are able to safely grow our Agapanthus ‘Tinkerbell’, radiant Azaleas, Bird of paradise, beautiful Camellias, Ceanothus horizontalis, Clivias miniata, various ferns, Grewia caffra, colorful Hydrangeas, Loropetalums, king and Roubelinii palms, Phormiums, Podocarpus henkelii, Tecoma capensis, Tristanea conferta, and many more that require extra tender care.</p>

            <Link to="/inventory">
              <button className="bg-white hover:bg-site-green-dark text-site-green-dark hover:text-white text-base font-medium text-center px-6 py-2 border border-site-green-dark mt-9">
                View Our Full Inventory
              </button>
            </Link>

            <div className="w-full bg-site-green-dark py-2 mt-5 mb-8">
              <h2 className="text-white text-lg font-semibold text-center uppercase">Photo Gallery</h2>
            </div>

            <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-6 mx-auto">
              {images.map((image, index) => (
                <div key={index} onClick={() => openCarousel(index)}>
                  <GatsbyImage
                    alt={`Mesa Drive ${index + 1}`}
                    image={image}
                    className="w-full h-full object-cover cursor-pointer"
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="w-full mx-auto border mt-12">
            <JotformEmbed src="https://form.jotform.com/233212775091453" />
          </div>
        </div>
      </div>

      {/* Conditionally render ImageCarousel */}
      {carouselIsOpen && (
        <ImageCarousel
          isOpen={carouselIsOpen}
          closeCarousel={closeCarousel}
          images={images}
          selectedImageIndex={selectedImageIndex}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "backgrounds/banner7.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1180
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    allFile(filter: { relativePath: { regex: "/mesa/mesa-/" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            width: 800
            height: 800
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`;

export default MesaDrivePage
